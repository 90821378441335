// 设置token 携带
export const setToken = (token) => ({ type: 'setToken', payload: token });

//存储用户名字
export const setUser = (info) => ({ type: 'setUser', payload: info });

//存储首页数据
export const setMainList = (list) => ({ type: 'setList', payload: list });

//存储管理员列表
export const setUserList = (list) => ({ type: 'setUserList', payload: list });
